import { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import FormFields from '@components/Form/FormFields';
import { FormField, RequestStatus } from '@/types';
import Button from '@components/Button';
import Link from 'next/link';

const formFields: FormField[] = [
  {
    id: 5,
    sort_order: 0,
    clean_name: 'vor_und_nachname',
    label: 'Vor- und Nachname',
    field_type: 'singleline',
    required: true,
    choices: '',
    default_value: '',
    help_text: '',
    page_id: 27,
    fieldset: '',
    legend: '',
    fields: [],
  },
  {
    id: 6,
    sort_order: 1,
    clean_name: 'telefonnummer',
    label: 'Telefonnummer',
    field_type: 'singleline',
    required: true,
    choices: '',
    default_value: '',
    help_text: '',
    page_id: 27,
    fieldset: '',
    legend: '',
    fields: [],
  },
  {
    id: 35,
    sort_order: 2,
    clean_name: 'ich_akzeptiere_die_datenschutzbestimmungen',
    // eslint-disable-next-line no-useless-escape, prettier/prettier
    label: 'Ich akzeptiere die <a href=\"/datenschutz\">Datenschutzbestimmungen.</a>',
    field_type: 'checkbox',
    required: true,
    choices: '',
    default_value: '',
    help_text: '',
    page_id: 27,
    fieldset: '',
    legend: '',
    fields: [],
  },
];

function ContactPopup() {
  const [status, setStatus] = useState<RequestStatus>('idle');

  return (
    <div className='contactpopup'>
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className='IconButton' aria-label='Update dimensions'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              aria-describedby='desc'
              aria-labelledby='title'
              viewBox='0 0 64 64'
            >
              <path
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                d='M15 10h34M15 48h34'
                data-name='layer2'
              />
              <rect
                width='34'
                height='60'
                x='15'
                y='2'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                data-name='layer1'
                rx='4'
                ry='4'
              />
              <circle
                cx='32'
                cy='55'
                r='2'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                data-name='layer1'
              />
            </svg>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className='PopoverContent'
            sideOffset={5}
            collisionPadding={{ left: 20, right: 20, top: 350 }}
            alignOffset={33}
          >
            <form
              id='formular'
              method='POST'
              className='form callback-form'
              onSubmit={async event => {
                event.preventDefault();

                const formData = new FormData(event.currentTarget);

                const data: {
                  [key: string]: FormDataEntryValue | FormDataEntryValue[];
                } = Object.fromEntries(formData);

                formFields.forEach(field => {
                  if (field.field_type !== 'checkboxes') return;
                  data[field.clean_name] = formData.getAll(field.clean_name);
                });

                try {
                  setStatus('loading');
                  const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v2/forms/`, {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      page: 27,
                      form_data: data,
                    }),
                  });

                  if (res.ok) {
                    setStatus('success');
                  } else {
                    setStatus('error');
                  }
                } catch (error) {
                  setStatus('error');
                }
              }}
            >
              <FormFields formFields={formFields} />
              {status === 'success' ? (
                <div className='message message--success'>
                  <p>
                    Vielen Dank für Ihre Anfrage. Wir werden uns schnellstmöglich mit Ihnen in
                    Verbindung setzen.
                  </p>
                </div>
              ) : null}
              {status === 'error' ? (
                <div className='message message--error'>
                  <p>
                    Leider ist etwas schief gegangen... Bitte versuchen Sie es später erneut oder
                    senden Sie Ihre Anfrage direkt an die E-Mail-Adresse{' '}
                    <a href='mailto:info@busche-zelte.de'>info@busche-zelte.de</a>.
                  </p>
                </div>
              ) : null}
              <Button
                primary
                type='submit'
                disabled={status === 'loading' || status === 'success'}
                className='form__submit'
              >
                Rückruf anfordern
              </Button>
              <p className='callbackText'>
                Wir melden uns innerhalb von 24 Stunden des nächsten Werktags oder nutzen Sie unser
                allgemeines <Link href='./kontakt'>Kontaktformular</Link>
              </p>
            </form>
            <Popover.Close className='PopoverClose' aria-label='Close'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill-rule='evenodd'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-miterlimit='10'
                clip-rule='evenodd'
                viewBox='0 0 26 25'
              >
                <path fill='none' d='M0 0h25v25H0z' />
                <path
                  fill='none'
                  stroke='#202020'
                  stroke-width='2'
                  d='m23.48 1.47-22 22m22 0-22-22'
                />
              </svg>
            </Popover.Close>
            <Popover.Arrow className='PopoverArrow' />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}

export default ContactPopup;
