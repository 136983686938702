import React from 'react';
import Label from '../Label';

type Props = {
  label: string;
  id: string;
  name?: string;
  required?: boolean;
  placeholder?: string;
  default_value?: string;
};

function Textarea(props: Props) {
  const { label, id, name, required, placeholder, default_value } = props;

  return (
    <div className='textarea'>
      <Label text={`${label}${required ? '' : ' (optional)'}`} htmlFor={id} />
      <textarea id={id} name={name ?? id} required={required} placeholder={placeholder}>
        {default_value}
      </textarea>
    </div>
  );
}

export default Textarea;
