import { FormField } from '@/types';
import Textarea from '@components/Form/Textarea';
import React from 'react';

function MultiLineField(props: FormField) {
  const {
    label,
    clean_name: cleanName,
    help_text: helpText,
    required,
    default_value: defaultValue,
  } = props;

  return (
    <Textarea
      label={label}
      id={cleanName}
      placeholder={helpText}
      required={required}
      default_value={defaultValue}
    />
  );
}

export default MultiLineField;
