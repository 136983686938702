import React from 'react';
import ContactPopup from '@components/ContactPopup';
import RawHtml from '@components/RawHtml';

type Props = {
  title: string;
  subtitle?: string | null;
  showContactSticky?: boolean;
};

function Hero(props: Props) {
  const { title, subtitle, showContactSticky } = props;

  return (
    <div role='banner' className='hero'>
      <div className='container'>
        <h1 className='hero__title'>{title}</h1>
        {subtitle ? <RawHtml className='hero__subtitle' html={subtitle} /> : null}
        {showContactSticky ? <ContactPopup /> : null}
      </div>
    </div>
  );
}

export default Hero;
